.forgot-pwd-modal {

  &.MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
    display: block;
  }

  @media screen and (max-width: 920px) {
    height: fit-content;
    margin: auto 16px;
  }

  .MuiPaper-root {
    border-radius: 30px;
  }

  .MuiDialogTitle-root {
    font-size: 40px;
    line-height: 54px;
    font-weight: bold;
    text-align: left;
    padding: 5px 24px;

    .MuiTypography-root {
      font-size: 40px;
      line-height: 54px;
      font-weight: bold;
      text-align: left;

      @media screen and (max-width: 920px) {
        font-size: 24px;
        line-height: 54px;
      }
    }
  }
}

.forgot-pwd-form-ctn {
  //height: 100%;
  display: flex;
  flex-direction: column;
  
  .usr-input  {
    display: flex;
    justify-content: space-between;
  }

  .select-input {
    display: flex;
    justify-content: normal !important;
  }

  .usr-input,
  .code-input,
  .pwd-input {
    // display: inline-grid;

    .MuiFormControl-root {
      margin: 0
    }
  }

  .confirm-btn {
    height: 60px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      border-radius: 8px;
      height: 44px;
    }
  }

  .MuiIconButton-root {
    padding: 0;
    opacity: 1;
    color: var(--ion-text-color) !important;
  }

  .error-msg {
    color: var(--ion-color-danger);
    margin-top: 20px;
    text-align: center;
  }

  .success-msg {
    color: var(--ion-color-success);
    margin-top: 20px;
    text-align: center;
  }

  .usr-input,
  .pwd-input,
  .code-input {
    margin-top: 12px;
  }

  .number-field {
    width: 104% !important;
    margin-left: 10%;
  }
  .text-field {
   width: 132% ;
    margin-left: 10%;
  }
  
  .number-field,
  .text-field,
  .user-name,
  .pwd-field {
    // max-width: 341px;
    border-radius: 10px;
    height: 48px;
    width: 100%;
    background-color: #EAEEF3 !important;

    .MuiFormControl-root {
      margin: 0;
    }

    .MuiInputBase-root {
      background-color: #EAEEF3 !important;
      border-radius: 10px;

      .MuiInputBase-input {
        border: 0;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }
}

@media (min-width: 350px) and (max-width: 420px) {
  .number-field,
  .text-field,
  .user-name,
  .pwd-field,
  .login-form-btn {
    width: 100% !important;
  }
}

@media (min-width: 220px) and (max-width: 349px) {
  .number-field,
  .text-field,
  .user-name,
  .pwd-field,
  .login-form-btn {
    max-width: 100% !important;
  }
}.option-selection{
 // height: 45px;
  //width: 20%;
  background-color: #EAEEF3;
  border-radius: 10px;
}.form-control {
  height: 50px !important;
  width: 100%  !important;
}
@media (min-width: 320px) and (max-width: 520px) {
  .form-control {
  max-width: 100% !important;
  }
}
.send-btn {
  width: 28%;
  height: 30px !important;
  margin-left: 72% !important;
  margin-top: -3% !important;
}

@media (min-width: 320px)and(max-width: 320px) {
.MuiInputBase-input {
  width: 138px !important;
}
}
@media (min-width: 375px) {
  .MuiInputBase-input {
    width: 186px !important;
  }
  }
  @media (min-width: 390px) and (max-width: 390px)  {
    .MuiInputBase-input {
      width: 204px !important;
    }
  }
  @media (min-width: 425px) {
    .MuiInputBase-input {
      width: 234px !important;
    }
    }
    @media (min-width: 768px) {
      .MuiInputBase-input {
        width: 250px !important;
      }
      }

    