.login-form-page {
  .title-row {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .logo {
      width: 130px;
    }
  }

  .login-form-ctn {
    min-width: 360px;
    display: flex;
    padding: 25px 35px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.09);
    border-radius: 4px;

    .card-title {
      font-size: 22px;
      color: var(--ion-color-primary);
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }

    .page-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      line-height: 24px;
      color: var(--ion-color-secondary-contrast);
      font-family: var(--headers-font-family);
    }

    .usr-input,
    .code-input,
    .pwd-input {
      display: inline-grid;

      .input-labell {
        color: #fff !important;
      }
    }

    .pwd-input {
      margin-top: 13px;
    }

    .form-control {
      margin: 10px 0;

      .MuiFormControl-root {
        background: #1f2e52 !important;

        .MuiInputBase-root {
          background: #1f2e52 !important;
          color: #fff !important;
        }
      }
    }

    .MuiInputBase-input {
      font-size: 16px;
      font-family: 'Roboto';
    }

    .MuiIconButton-root {
      padding: 0;
      opacity: 0.4;
    }

    .login-err-msg {
      color: red;
      margin-top: 20px;
      text-align: center;
      max-width: 250px;
    }

    .user-name,
    .pwd-field {
      max-width: 341px;
      height: 48px;

      .input-label {
        color: #fff !important;
      }
    }

    .login-btns {
      display: flex;
      justify-content: center;
      align-items: center;

      .login-form-btn {
        height: 48px;
        width: 48%;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast) !important;
        margin: 20px 5px 0px 5px;
      }

      .login-form-btn-without-demologin {
        height: 48px;
        width: 100%;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast) !important;
        margin: 20px 5px 0px 5px;
      }
    }

    .forgot-pwd-link {
      // margin-top: 16px;
      margin-bottom: 20px;
      font-size: 16px;
      color: var(--ion-color-secondary-contrast);
      text-align: center;
      cursor: pointer;
    }

    .bottom-link {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 16px;
      color: var(--ion-color-secondary-contrast);
      text-align: center;
      text-decoration: underline;
      cursor: pointer;

      .register-link {
        color: var(--ion-color-secondary-contrast);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .login-form-page {
    width: 100%;
    padding: 0px;

    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      padding: 10px 18px !important;

      .user-name,
      .pwd-field,
      .login-form-btn {
        max-width: 274px;
      }
    }
  }
}

@media only screen and (device-width: 360px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      padding: 10px 18px !important;
      margin-left: 0px;

      .login-btns {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
  }
}

@media only screen and (device-width: 411px) and (device-height: 823px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      padding: 10px 18px !important;

      .login-btns {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (device-width: 375px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      padding: 10px 18px !important;
      margin-left: 0px;

      .login-btns {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }
  }
}

@media only screen and (min-device-width: 350px) and (device-height: 420px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      padding: 10px 18px !important;

      .login-btns {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-device-width: 320px) {
  .login-form-page {
    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 250px !important;
      max-width: 250px !important;
      padding: 10px 18px !important;

      .page-title {
        font-size: 16px;
        line-height: 16px;
      }

      .MuiInputBase-input {
        font-size: 13px;
        font-family: 'Roboto';
      }

      .user-name,
      .pwd-field {
        height: 38px;
      }

      .login-form-btn {
        height: 30px;
        font-size: 13px;
      }

      .login-btns {
        display: flex;
        flex-direction: column;
        .login-form-btn {
          width: 100%;
        }
      }
    }
  }
}